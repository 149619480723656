<template>
  <section class="live-quiz">
    <div class="live-container">
        <div class="container-md d-flex justify-content-center">
            <div class="card-live-header mx-5 px-3">
                <div class="pt-5 pb-2 px-5 mx-5">
                    <h4 class="text-white">Copy or Share for play quiz</h4>
                    <div class="pt-3 pb-3 text-left">
                        <div class="row">
                            <div class="col-8">
                                <div class="mr-3">
                                    <label for="" class="text-white text-left font-weight-lighter">Join Quiz Link</label>
                                    <div class="input-group mb-3">
                                        <input id='link-quiz' type="text" ref="clone" v-on:focus="$event.target.select()" class="form-control" readonly v-model="link" aria-describedby="inputGroup-sizing-sm">
                                        <div class="input-group-append">
                                            <button class="btn btn-light outline-sec" v-clipboard:copy="link" v-clipboard:success="onCopy" v-clipboard:error="onError" type="button" id="button-addon2"><b-icon icon="files"></b-icon></button>
                                        </div>
                                    </div>
                                    <label for="" class="text-white font-weight-lighter mb-2">or share via :</label>
                                    <div class="d-flex">
                                        <div v-for="(data, index) in shareVia" :key="index" type="button" class="mr-2">
                                            <a :href="data.link" target="_blank">
                                                <img :src="require(`@/assets/images/logo/${data.logo}`)" alt="" class="mr-1">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="bg-white w-100 qr-bg d-flex align-items-center justify-content-center" type="button" @click="$bvModal.show('scan-qr')">
                                    <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://play.soca.ai/event/'+codeQuiz" alt="" class="qr-size">
                                </div>
                                <p class="mb-0 mt-1 font-weight-normal text-white text-center label-code">Join with QR Code</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="$route.query.key == 'ramean'" class="container-md mt-3 pb-5 d-flex justify-content-center">
            <div class="card-live-content">
                <div class="d-flex justify-content-between mx-5 py-4">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/images/icons/sum_peserta.svg" alt="peserta">
                        <span class="text-white ml-2">{{ totalPesertaTeam }} Participant</span>
                    </div>
                    <button class="btn btn-light"><b-icon icon="shuffle" class="mr-1"></b-icon> Acak Tim</button>
                </div>
                <div class="row mx-5 py-4">
                    <div v-for="(data, index) in pesertaTeam" :key="index" class="col-md-3">
                        <div class="card card-peserta mr-3 mt-3">
                            <div class="card-body affix-content">
                                <div class="d-flex justify-content-between mb-2">
                                    <div class="text-white text-left mr-5">
                                        <h5>{{ data.team_name }}</h5>
                                        <p class="text-muted mt-1">{{ data.data_player.length }} Pemain</p>
                                    </div>
                                    <div>
                                        <img :src="require(`@/assets/images/users/default_t${index+1}.svg`)" alt="">
                                    </div>
                                </div>
                                <div v-if="data.data_player.length > 0">
                                    <div class="overflow-auto list-users">
                                        <div v-for="(peserta, indexP) in data.data_player" :key="indexP" class="d-flex align-items-center mt-3">
                                            <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + peserta.user_name" class="img-peserta rounded-circle" alt="">
                                            <p class="mb-0 text-white ml-2 text-truncate">{{ peserta.user_name }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <div class="d-flex justify-content-center mt-5">
                                        <h5 class="text-muted font-weight-normal">Waiting for participant ...</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-4">
                    <button class="btn btn-success btn-lg" @click="startQuiz"><h5 class="font-weight-normal mx-3 my-1" @click="startQuiz">PLAY QUIZ</h5></button>
                    <h5 class="font-weight-normal mt-3 text-white"> Peserta tidak dapat bergabung setelah pertandingan dimulai</h5>
                </div>
            </div>
        </div>

        <div v-else-if="$route.query.key == 'seru-seruan'" class="container-md mt-3 pb-5 d-flex justify-content-center">
            <div class="card-live-content container-fluid">
                <div class="d-flex justify-content-between mx-5 py-4">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/images/icons/sum_peserta.svg" alt="peserta">
                        <span class="text-white ml-2">{{ pesertaSeruSeruan.length }} Participant</span>
                    </div>
                </div>
                <div v-if="pesertaSeruSeruan.length > 0" class="d-flex mx-5 pt-3 pb-5">
                    <div class="row w-100 overflow-auto">
                        <div v-for="(data, index) in pesertaSeruSeruan" :key="index" class="col-md-3 mt-3">
                            <div class="list-peserta d-flex align-items-center mr-2">
                                <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + data.user_name" class="img-peserta rounded-circle ml-3" alt="">
                                <p class="text-white mb-0 pl-3 text-truncate mr-1">{{ data.user_name }}</p>
                            </div>
                            <!-- <vs-icon icon="delete_outline" type="button" size="18px" color="white" class="mr-3"></vs-icon> -->
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="d-flex align-center justify-content-center mt-5 pt-3 pb-5">
                        <p class="text-white mb-0">Waiting for participant ....</p>
                    </div>
                </div>
                <div class="mb-5 mt-5">
                    <button v-if="pesertaSeruSeruan.length > 0" class="btn btn-success btn-lg" @click="startQuiz"><h5 class="font-weight-normal mx-3 my-1">PLAY QUIZ</h5></button>
                    <button v-else class="btn btn-success btn-lg disabled"><h5 class="font-weight-normal mx-3 my-1">PLAY QUIZ</h5></button>
                </div>
            </div>
        </div>

        <div v-else class="container-md mt-3 pb-5 d-flex justify-content-center">
            <div class="card-live-content">
                <div class="d-flex justify-content-between mx-5 py-4">
                    <div class="d-flex align-items-center">
                        <img src="@/assets/images/icons/sum_peserta.svg" alt="peserta">
                        <span class="text-white ml-2">{{ pesertaDuel[0].data_player_duel.length + pesertaDuel[1].data_player_duel.length }} Peserta</span>
                    </div>
                </div>
                <div class="d-flex justify-content-center position-relative mx-5 pt-3 pb-5">
                    <div v-if="pesertaDuel.length > 0" class="position-absolute d-flex justify-content-center mt-0">
                        <img src="@/assets/images/logo/versus.svg" alt="">
                    </div>
                    <div v-if="pesertaDuel.length > 0" class="duel-peserta d-flex align-items-center justify-content-between">
                        <div class="my-2">
                            <div v-if="pesertaDuel[0].data_player_duel.length > 0">
                                <div v-for="(peserta1, index) in pesertaDuel[0].data_player_duel" :key="index">
                                    <div class="d-flex align-items-center ml-2 my-3">
                                        <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + peserta1.user_name" class="img-peserta rounded-circle ml-3" alt="">
                                        <p class="mb-0 ml-2 text-white">{{ peserta1.user_name }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else>
                                <p class="mb-0 ml-5 text-muted">Waiting for participant ...</p>
                            </div>
                        </div>
                        <div class="my-2">
                            <div v-if="pesertaDuel[1].data_player_duel.length > 0">
                                <div v-for="(peserta2, index) in pesertaDuel[1].data_player_duel" :key="index">
                                    <div class="d-flex align-items-center mr-4 my-3">
                                        <img :src="'https://ui-avatars.com/api/?background=405DC3&color=fff&name=' + peserta2.user_name" class="img-peserta rounded-circle ml-3" alt="">
                                        <p class="mb-0 ml-2 text-white">{{ peserta2.user_name }}</p>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="my-3">
                                <p class="mb-0 mr-5 text-muted">Waiting for participant ...</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-5">
                    <button v-if="peserta.length > 0" class="btn btn-success btn-lg" @click="startQuiz"><h5 class="font-weight-normal mx-3 my-1">PLAY QUIZ</h5></button>
                    <button v-else class="btn btn-success btn-lg disabled"><h5 class="font-weight-normal mx-3 my-1">PLAY QUIZ</h5></button>
                </div>
            </div>
        </div>
    </div>

    <!-- MODAL SCAN -->
        <b-modal id="scan-qr" modal-class="scan-qr" centered header-class="border-0 pt-4 pb-0" :hide-footer="true" :hide-header="true" footer-class="border-0 pb-3 pt-0 mx-auto" size="xl">
            <div class="float-right">
                <button class="btn btn-close d-flex justify-content-center align-items-center" @click="$bvModal.hide('scan-qr')"><b-icon icon="x"></b-icon></button>
            </div>
            <div class="w-100 d-flex justify-content-center mb-3">
                <img :src="'https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://play.soca.ai/event/'+codeQuiz" alt="" class="modal-qr">
            </div>
        </b-modal>
  </section>
</template>
<script>
import io from "socket.io-client"
import "element-ui/lib/theme-chalk/index.css";
import "element-tiptap/lib/index.css";
import $ from "jquery";
import {base_url_socket} from '@/config/base_url'
import {getQuizResultsByEventAll} from '@/services/quiz-results/quiz-results.service'
import { getPesertaTeam } from '@/services/quiz-events/quiz-events.service'
import { getPesertaDuel } from '@/services/quiz-events/quiz-events.service'
import { getStartQuiz } from '@/services/quiz-events/quiz-events.service'
import { AlertUtils } from "@/mixins/AlertUtils";


export default {
mixins: [AlertUtils],
  data() {
    return {
      socket : io(base_url_socket),
      additional : false,
      pesertaSeruSeruan:[],
      pesertaTeam : [],
      pesertaDuel : [],
      totalPesertaTeam : 0,
      quiz : false,
      polling : false,
      training : false,
      tipeQuiz : null,
      codeQuiz : null,
      timer : null,
      formData: {
        grade_id: null,
        category_id: null,
        learning_category_id: null,
        quiz_name: null,
      },
      link : 'https://play.soca.ai/event/',
      kode : '808080',
      shareVia : [
        {
            logo : 'wa.png',
            link : 'https://api.whatsapp.com/send?text=Yuk%20Join%20Quiz%20Sekarang%20%3A%0Ahttps%3A%2F%2Fplay.soca.ai%2Fevent%'
        },
        {
            logo : 'fb.png',
            link : 'https://www.facebook.com/'
        },
        {
            logo : 'ig.png',
            link : 'https://www.instagram.com/'
        },
        {
            logo : 'linkedin.png',
            link : 'https://www.linkedin.com/'
        },
        {
            logo : 'gmail.png',
            link : 'https://mail.google.com/'
        },
          
    ],
    timPeserta : [
        {
            namaTim : 'Tim Serigala',
            peserta : [
                {
                    namaPeserta : 'Asep Balon',
                },
                {
                    namaPeserta : 'Dadang RCB',
                },
                {
                    namaPeserta : 'Wawan Bajred',
                },
                {
                    namaPeserta : 'Isman Konelo',
                },  
            ]
        },
        {
            namaTim : 'Tim Harimau',
            peserta : [
                {
                    namaPeserta : 'Asep Balon',
                },
                {
                    namaPeserta : 'Dadang RCB',
                },
                {
                    namaPeserta : 'Wawan Bajred',
                },
            ]
        },
        {
            namaTim : 'Tim Serigala',
            peserta : [
                {
                    namaPeserta : 'Asep Balon',
                },
            ]
        },
        {
            namaTim : 'Tim Serigala',
            peserta : [
            ]
        },
    ],

    peserta : [
        {
            nama : 'Isman Karbon',
        },
        {
            nama : 'Nanang konelo',
        },
    ],
    users:{}
      
    };
  },
  methods: {
    setSideBar() {
      this.$store.commit("IS_SIDEBAR_ACTIVE", false);
    },

    chooseType(type){
      if(type == 'quiz'){
        this.quiz = true;
        this.polling = false;
        this.training = false;
      } else if(type == 'polling'){
        this.quiz = false;
        this.polling = true;
        this.training = false;
      } else {
        this.quiz = false;
        this.polling = false;
        this.training = true;
      }
      
    },

    onCopy: function () {
        this.alertSuccess('Link successfully copied')
    },

    onError: function () {
        this.alertFail('Link failed to copy')
    },

    startQuiz(){
        const event_id = this.$route.query.id
        const tipe = this.$route.query.key
        getStartQuiz(event_id)
        .then((response) => {
            if(response.status && response.status == true){
                localStorage.removeItem("sisaWaktu");
                this.$router.push({ name : 'Leaderboard-Quiz', query: {key:tipe, id : event_id, gc: this.codeQuiz} })
                this.socket.emit("send_message", { message: this.codeQuiz })
            }
        })
    },

    getPesertaSeruSeruan(){
        const event_id = this.$route.query.id
        getQuizResultsByEventAll(event_id)
        .then((response) => {
            if (response.status && response.status == true){
                this.pesertaSeruSeruan = response.data
                // if(this.users.subscribe == null || this.users.subscribe == false){
                    // if(this.pesertaSeruSeruan.length == 20 || this.pesertaSeruSeruan.length >= 20){
                    //     this.$bvModal.show('subs-modal')
                    // }
                // }
            }
        })
    },

    getPesertaTeam(){
        const event_id = this.$route.query.id
        this.totalPesertaTeam = 0
        getPesertaTeam(event_id)
        .then((response) =>{
            if(response.status && response.status == true){
                this.pesertaTeam = response.data
                this.pesertaTeam.forEach((element) =>{
                    this.totalPesertaTeam += element.data_player.length
                })
            }
        })
    },

    getPesertaDuel(){
        const event_id = this.$route.query.id
        getPesertaDuel(event_id)
        .then((response) =>{
            if(response.status && response.status == true){
                this.pesertaDuel=(response.data)
            }
        })
    },

  },
  mounted() {
    const tipe = this.$route.query.key
    this.tipeQuiz = tipe;

    const code = this.$route.query.gc
    this.codeQuiz = code;

    if(localStorage.getItem('users')){
        this.users = JSON.parse(localStorage.getItem('users'))
    }

    this.shareVia[0].link = "https://api.whatsapp.com/send?text=Yuk%20Join%20Quiz%20Sekarang%20%3A%0Ahttps%3A%2F%2Fplay.soca.ai%2Fevent%2F" + this.codeQuiz
    this.shareVia[1].link = "https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fplay.soca.ai%2Fevent%2F" + this.codeQuiz
    this.shareVia[3].link = "http://www.linkedin.com/shareArticle?mini=true&url=https://play.soca.ai/event/" + this.codeQuiz
    this.shareVia[4].link = "mailto:?subject=Main%20SOCA%20Quiz%20Sekarang%20%21&body=Yuk%20Join%20Quiz%20Sekarang%20%3A%0Ahttps%3A%2F%2Fplay.soca.ai%2Fevent%2F" + this.codeQuiz
    this.link = this.link + this.codeQuiz

    $("section.live-quiz").parent().addClass("m-0");
    this.setSideBar();
    this.$nextTick(() => {
        let vm = this
        vm.$refs.clone
    })

    if(tipe == 'seru-seruan'){
        this.timer = setInterval(() =>{
            this.getPesertaSeruSeruan()
        }, 5000)
    } else if(tipe == 'ramean') {
        this.timer = setInterval(() =>{
            this.getPesertaTeam()
        }, 5000)
    } else {
        this.timer = setInterval(() =>{
            this.getPesertaDuel()
        }, 5000)
    }
    

    this.socket.emit('createRoom', this.codeQuiz)
  },

  beforeDestroy(){
    clearInterval(this.timer)
    clearInterval(this.totalPesertaTeam)
  }

};
</script>

<style scoped>
::v-deep .scan-qr .modal-dialog > .modal-content{
    background-color: transparent;
    border: 0px !important;
}
.card-live-header{
    border-radius: 20px;
    width: 65%;
    background: rgba(0, 0, 0, 0.5);
    background: rgba(12, 0, 0, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(12, 0, 0, 0.3);
    position: relative;
}

.card-live-content{
    border-radius: 20px;
    width: 100%;
    background: rgba(12, 0, 0, 0.6);
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(8.3px);
    -webkit-backdrop-filter: blur(8.3px);
    border: 1px solid rgba(12, 0, 0, 0.3);
    position: relative;
}

.card-peserta{
    border-radius: 20px;
    background: rgba(0, 0, 0, 0.8);
    position: relative;
    height:280px
}

.list-peserta{
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.8);
    height:60px;
    width:210px;
}

.duel-peserta{
    border-radius: 15px;
    background: rgba(0, 0, 0, 0.8);
    height:100%;
    width:100%;
}

.live-container{
    padding-top: 100px;
}

.dot{
    height: 5vh;
    width: 5vh;
    border-radius:50%;
    display: inline-block;
    background-color: #fff;
}

.img-peserta{
    width: 23px;
    height: 23px;
}

.qr-bg{
    height: 10rem;
    border-radius: 10px
}

.qr-size{
    height : 8.75rem ;
    width: 9.25rem;
}

.label-code{
    font-size : 14px;
    font-weight: 100 !important;
}

.card-peserta .list-users{
    height : 10rem;
}

.card-peserta .list-users::-webkit-scrollbar{
    display: none;
}

.btn-close{
    background-color: #EDEDED;
    border-radius: 50%;
    width: 35px;
    height: 35px;
}

.modal-qr{
    height: 30rem;
    width: 30rem
}

@media (max-width: 1366px){
  .qr-size{
    height : 8.75rem ;
    width: 8rem;
}
}
</style>

<style>
html {
  height: 100% !important;
}
body {
  height: 100% !important;
}
#app {
  height: 100% !important;
}
.main-wrapper {
  height: 100% !important;
}
.main-container-fluid {
  /* height: 100% !important;
  margin-left: 0px !important;
  padding: 0 !important; */
}
.live-quiz {
  height: auto;
  width: 100%;
  background-size: cover;
  background-image: url("../../../assets/images/background/bg-space-web.png");
}
.container-content{
    padding-top:20vh ;
    left: 30vw;
    width: 50%;
    margin-right: 35rem;
    z-index: 3;
    position: relative;
}
#scene-container-content {
  width: 100%;
  height: 110%;
  position: absolute;
  left: -25vw;
  /* top: 5vh; */
}
#scene-container-content div {
  display: none !important;
}
.container-filter {
  background: rgba(0, 0, 0, 0.5);
  position: relative;
}
.container-question {
  width: 67.5%;
}
.konten-text{
    background: #E29CFF;
    border-radius: 20px;
}

.konten-text:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    left: -42px;
    top: 17px;
    /* border: 10px solid transparent;
        border-right: 32px solid rgb(193,193,193); /* IE8 Fallback */
    /* border-right: 32px solid rgba(193,193,193,0.5);  */
    z-index: 2;
}

.konten-text:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  left: -45px;
  top: 180px;
  border: 15px solid transparent;
  border-right: 30px solid #E29CFF;
  z-index: 3;
}



</style>
