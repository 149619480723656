import { render, staticRenderFns } from "./liveQuiz.vue?vue&type=template&id=7723163a&scoped=true&"
import script from "./liveQuiz.vue?vue&type=script&lang=js&"
export * from "./liveQuiz.vue?vue&type=script&lang=js&"
import style0 from "./liveQuiz.vue?vue&type=style&index=0&id=7723163a&prod&scoped=true&lang=css&"
import style1 from "./liveQuiz.vue?vue&type=style&index=1&id=7723163a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7723163a",
  null
  
)

export default component.exports