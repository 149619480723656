import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getQuizResultsByEvent(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuizResults + "/event/" + id
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }

  export async function getQuizResultsByEventAll(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuizResults + "/event-all/" + id
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }


  export async function getQuizResultsByResultId(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuizResults + "/detail/" + id
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }

  export async function getQuizTeamLeaderboard(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuizTeam + "/leaderboard/" + id
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }

  export async function getQuizDuelLeaderboard(id){
    return axios({
        method : 'GET',
        url : ApiRoute.getQuizDuel + "/leaderboard/" + id
      }).then((res) => {
        const data = {
            status:res.data.status, 
            data:res.data.data, 
            message:res.data.message
        }
        return data;
      }).catch((error) => {
        const data = {status : false , response : error }    
        return data;
      })
  }