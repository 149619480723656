import axios from "../../config/axios"
import ApiRoute from '../api.route';

export async function getQuizEvents() {
  return axios({
    method: 'GET',
    url: ApiRoute.postQuizEvents
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizEventsDetail(id) {
  return axios({
    method: 'GET',
    url: ApiRoute.postQuizEvents + '/detail/' + id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizEventsDetailQuestions(id) {
  return axios({
    method: 'GET',
    url: ApiRoute.postQuizEvents + '/detail-question/' + id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getQuizEventsDetailResultQuestions(event_id, quiz_results_id) {
  return axios({
    method: 'GET',
    url: ApiRoute.postQuizEvents + '/detail-result-question/' + event_id + '/' + quiz_results_id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function postQuizEvents(formData) {
  return axios.post(ApiRoute.postQuizEvents, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function postQuizTeam(formData, id) {
  return axios.post(ApiRoute.getQuizTeam + '/' + id, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function getPesertaTeam(event_id) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizTeam + '/' + event_id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function postQuizDuel(formData, id) {
  return axios.post(ApiRoute.getQuizDuel + '/' + id, formData)
    .then(res => {
      const data = res.data
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function getPesertaDuel(event_id) {
  return axios({
    method: 'GET',
    url: ApiRoute.getQuizDuel + '/' + event_id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function getStartQuiz(event_id) {
  return axios({
    method: 'GET',
    url: ApiRoute.postQuizEvents + '/start/' + event_id
  }).then((res) => {
    const data = {
      status: res.data.status,
      data: res.data.data,
      message: res.data.message
    }
    return data;
  }).catch((error) => {
    const data = { status: false, response: error }
    return data;
  })
}

export async function imporSoal(id) {

  return axios.get(ApiRoute.postQuizEvents + '/download/' + id, { responseType: 'blob' })
    .then(res => {
      const data = res
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}

export async function getSearchLaporan(params) {

  return axios.get(ApiRoute.postQuizEvents, { params: params })
    .then(res => {
      const data = res
      return data;
    })
    .catch((error) => {
      const data = { status: false, response: error }
      console.log(error)
      return data;
    })
}